import React from "react"
import styled from "styled-components"
//import { Link } from "gatsby"

const Container = styled.div`
  max-width: 100vw;
  height: 10vh;
  margin: 0 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.textColor};
  transition: all 0.2s ease-out;
  @media only screen and (max-width: 768px) {
    margin: 0 2rem;
  }
`;

/*
const twitterLink = {
  href: "https://twitter.com/kushalshit",
  title: "Twitter",
}
*/

const Footer = ({ isDark, setIsDark }) => (
  <Container>
    © {new Date().getFullYear()}, Built with ❤️
    {` `}
    {/* <Link
      style={{ color: "inherit", textDecoration: "none" }}
      to={twitterLink.href}
    >
      {twitterLink.title}
    </Link> */}
  </Container>
)

export default Footer
