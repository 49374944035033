import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider} from 'styled-components'

import './styles/global.css'

import Header from './header'
import Footer from './footer'

import lightTheme from '../themes/light'
import darkTheme from '../themes/dark'

const Container = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.textColor};
  transition: all 0.2s ease-out;
`
const Main = styled.main`
  min-height: 90vh;
  display: flex;
  justify-content: center;
  //background-color: #f1cfb5;
  margin: 0 auto;
  max-width: 60%;
  //padding: 1rem 0.5em 1rem 0.5rem;
`

const Layout = ({ children }) => {
  let localIsDark = true;

  if (typeof window !== 'undefined') {
    if (localStorage.getItem('isDark') && localStorage.getItem('isDark') === 'false') {
      localIsDark = false
    } else {
      localIsDark = true
    }
    console.log(localIsDark ? 'dark mode' : 'light mode')
  }

  const [isDark, setIsDark] = useState(localIsDark)

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <Container>
        <Header isDark={isDark} setIsDark={setIsDark} />
        <Main>{children}</Main>
        <Footer></Footer>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
